<template>
  <div class="repayment-container">
    <div class="repayment">
      <div class="rem26 lh18">待付总额(元)</div>
      <div class="rem44 lh30 mt8">{{ borrowTotal }}</div>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      @load="onLoad"
      :immediate-check="false"
      v-if="showList"
    >
      <div
        class="repayment-content"
        v-for="(item, index) in loanList"
        :key="index"
        @click="toDetails(item)"
      >
        <div class="p15">
          <div class="flex just-between align-center">
            <div class="rem30 lh21">
              {{ item.busName }} 共<span>{{ item.tnr }}</span
              >期
              <van-tag color="#ffe1e1" text-color="#FF6646" v-if="item.odInd === '已逾期'">{{
                item.odInd
              }}</van-tag>
              <van-tag color="#f5f5f5" text-color="#b7bbbf" v-else-if="item.odInd === '已取消'">{{
                item.odInd
              }}</van-tag>
              <van-tag color="#f5f5f5" text-color="#b7bbbf" v-else-if="item.odInd === '已失效'">{{
                item.odInd
              }}</van-tag>
              <van-tag color="#f5f5f5" text-color="#b7bbbf" v-else-if="item.odInd === '已结清'">{{
                item.odInd
              }}</van-tag>
              <van-tag color="#EEF3FF" text-color="#4D7BFE" v-else>{{ item.odInd }}</van-tag>
            </div>
            <div class="rem28 lh15" v-show="item.hasDtl && item.odInd !== '已结清'">
              付款日<span>{{ item.endDt ? item.endDt.substr(-5) : '' }}</span>
            </div>
            <div class="rem28 lh15" v-show="item.odInd == '已结清'">
              结清日<span>{{ item.endDt ? item.endDt.substr(-5) : '' }}</span>
            </div>
          </div>
          <div class="rem26 lh18 mt12" v-show="item.hasDtl && item.odInd !== '已结清'">
            本期应付
          </div>

          <div
            class="flex just-between align-center"
            v-show="item.hasDtl && item.odInd !== '已结清'"
          >
            <div class="rem40 lh28 m-lr3">
              ￥<span>{{ item.instSumAmt }}</span>
            </div>
            <div class="rem30 lh21 c-8E929B">{{ item.perdNo }}/{{ item.tnr }}期</div>
          </div>

          <div>
            <!-- <div> -->
            <div class="rem26 lh18 mt12" v-show="!item.hasDtl || item.odInd == '已结清'">
              融资总额
            </div>
            <div v-show="!item.hasDtl || item.odInd == '已结清'">
              <div class="rem40 lh28 m-lr3" v-show="item.odInd !== '已结清'">
                ￥<span>{{ item.origPrcp }}</span>
              </div>

              <div class="flex align-center just-between" v-show="item.odInd == '已结清'">
                <div class="rem40 lh28 m-lr3">
                  ￥<span>{{ item.origPrcp }}</span>
                </div>
                <div class="rem30 lh21 c-8E929B">{{ item.perdNo }}/{{ item.tnr }}期</div>
              </div>
            </div>
            <!-- </div> -->

            <!-- <div v-show="item.odInd === '待提款'">
              <button>查看</button>
            </div> -->
          </div>

          <div class="rem26 lh18 c-8E929B" v-show="item.hasDtl && item.odInd !== '已结清'">
            融资总额：￥<span>{{ item.origPrcp }}</span>
          </div>
        </div>
        <van-divider />
        <div class="flex just-between align-center padding-rlr15">
          <div class="rem26 lh18">企业名称</div>
          <div class="rem26 lh18">{{ item.companyName }}</div>
        </div>
      </div>
    </van-list>
    <emptyPage v-if="loanList.length === 0"></emptyPage>
  </div>
</template>

<script>
import Vue from 'vue'
import { Divider, Tag, List } from 'vant'
import {
  getUnsetlLoanList,
  getRepaySumAmtInfoApi,
  getUserBindCardListApi,
  getLoanListApi,
} from '@/apis/repayment'
import { getBorrowFaceTokenApi } from '@/apis/borrow'
import { getScanFaceUrlApi } from '@/apis/credit'
import { getUid } from '@/utils/auth'
import emptyPage from './empty-page.vue'

Vue.use(Divider)
Vue.use(Tag)
Vue.use(List)

export default {
  data() {
    return {
      loading: false,
      finished: false,
      phoneNo: '',
      loanList: [],
      borrowTotal: 0,
      bankInfo: {}, // 银行卡信息
      total: '',
      maxNum: 0,
      showList: false,
      // uid: '',
      queryParams: {
        uid: '',
        pageSize: 10,
        pageNum: 1,
      },
      faceBackUrl: window.location.origin,
      myLoan:{},
    }
  },
  components: {
    emptyPage,
  },
  created() {
    this.queryParams.uid = getUid()
    this.getRepaySumAmtInfo()
    if (this.$route.query.type === 'UnsetlLoan') {
      this.getUnsetlLoanList()
    } else {
      this.getLoanList()
    }
    // this.getUnsetlLoanList()
    // this.getLoanList()
    this.myLoan = this.$store.state.repayment.loan;
  },

  methods: {
    onLoad() {
      this.queryParams.pageNum += 1
      if (this.$route.query.type === 'UnsetlLoan') {
        this.getUnsetlLoanList()
      } else {
        this.getLoanList()
      }
      // this.getLoanList()
      // this.getUnsetlLoanList()
      console.log('触发')
    },

    // 查询还款记录列表
    getUnsetlLoanList() {
      getUnsetlLoanList(this.queryParams).then((res) => {
        if (res.code === 200) {
          this.total = res.data.total
          this.newList = res.data.rows || []

          this.maxNum = Math.ceil(this.total / this.queryParams.pageSize)

          this.loading = false
          if (this.newList == null || this.newList.length === 0) {
            this.finished = true
            return
          }

          this.loanList.push(...this.newList)
          this.showList = true

          if (this.loanList.length === this.total) {
            this.finished = true
          }
        }
      })
    },

    // 我的借款详情
    toDetails(item) {
      this.$store.commit('loanFn', item)
      this.$store.commit('SET_LOANNO', item.loanNo)
      if (this.$route.query.type === 'Loan') {
        // console.log(item.hasDtl)
        if (
          item.odInd == '已取消' ||
          item.odInd == '已拒绝' ||
          item.odInd == '提款审批中' ||
          item.odInd == '待放款'
        ) {
          return false
        } else if (item.odInd == '已结清') {
          this.getUserBindCardList('repaymentInfo')
          // this.$router.push({
          //   path: 'repaymentInfo',
          // })
          return
        } else if (item.odInd == '待提款') {
          this.$router.push('/confirm-borrow')
          return
        } else if (item.odInd == '融资审批中' || item.odInd == '待发起' || item.odInd == '未刷脸') {
          this.$store.commit('SET_APPLY_SEQ', item.applySeq)
          switch (item.inSts) {
            case '10':
              this.$router.push('/apply-info')
              break
            case '11':
              this.$router.push('/invoice-info')
              break
            case '12':
              this.$router.push('/upload-certificate')
              break
            case '13':
              this.getBorrowFaceToken(item)
            case '15':
              // 刷脸
              this.getBorrowFaceToken(item)
              break
            case '18':
              // 刷脸
              this.getBorrowFaceToken(item)
              break
            case '20':
              this.$router.push('/face-recognition')
              break
            case '80':
              this.$router.push('/confirm-borrow')
              break
            case '500':
              this.$router.push('/face-recognition')
              break
            default:
              break
          }
        } else {
          this.getUserBindCardList('/repaymentDetail', item.canPreRepay)
          // this.$router.push({
          //   path: '/repaymentDetail',
          //   query: { canPreRepay: item.canPreRepay }, // 是否能提前还款
          // })
        }
        return
      } else {
        this.getUserBindCardList('/repaymentDetail', item.canPreRepay)
        // this.$router.push({
        //   path: '/repaymentDetail',
        //   query: { canPreRepay: item.canPreRepay }, // 是否能提前还款
        // })
      }
    },

    // 查询借款记录列表
    getLoanList() {
      getLoanListApi(this.queryParams).then((res) => {
        if (res.code === 200) {
          this.total = res.data.total
          this.newList = res.data.rows || []

          this.maxNum = Math.ceil(this.total / this.queryParams.pageSize)
          console.log(this.loanList)

          this.loading = false
          if (this.newList == null || this.newList.length === 0) {
            this.finished = true
            return
          }

          this.loanList.push(...this.newList)
          this.showList = true

          if (this.loanList.length === this.total) {
            this.finished = true
          }
        }
      })
    },

    // 刷脸
    getBorrowFaceToken(item) {
      getBorrowFaceTokenApi({
        applySeq: item.applySeq,
        callbackUrl: this.faceBackUrl + '/face-result?type=borrow',
      }).then((result) => {
        if (result.code === 200) {
          this.$store.commit('SET_FACE_TOKEN', result.data.faceToken)
          getScanFaceUrlApi(result.data.faceToken).then((r) => {
            window.location.href = r.data.originalUrl
          })
        }
      })
    },

    // 获取银行卡信息
    getUserBindCardList(path, query) {
      let param = {bindType: this.myLoan.daishouMerNo};
      getUserBindCardListApi(param).then((res) => {
        // eslint-disable-next-line prefer-destructuring
        this.bankInfo = res.data[0]
        this.$store.commit('bankFn', this.bankInfo)
        this.$router.push({
          path,
          query: { canPreRepay: query }, // 是否能提前还款
        })
      })
    },

    // 查看待还总额
    getRepaySumAmtInfo() {
      getRepaySumAmtInfoApi({
        userId: this.queryParams.uid,
        // 2899614
        data: { getAllAmt: '1' },
      }).then((res) => {
        if (res.code === 200) {
          this.borrowTotal = res.data.instSumAmt
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.repayment-container {
  height: 100vh;
  padding: 0 0.3rem /* 15/50 */;
  .repayment {
    margin-top: 21px;
    padding: 0.46rem /* 23/50 */ 0.3rem /* 15/50 */ 0.56rem /* 28/50 */ 0.3rem /* 15/50 */;
    width: 100%;
    background-color: #4d7bfe;
    box-sizing: border-box;
    border-radius: 5px;
    color: #fff;
  }
  .repayment-content {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    box-shadow: #000 15px;
    margin-top: 18px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    .p15 {
      padding: 0.3rem /* 15/50 */ 0.3rem /* 15/50 */ 0 0.3rem /* 15/50 */;
    }
    .padding-rlr15 {
      padding: 0 0.3rem /* 15/50 */ 0.3rem /* 15/50 */ 0.3rem /* 15/50 */;
    }
  }
}
</style>
