<template>
  <div class="empty-page">
    <van-image class="bg" width="237" height="167" :src="noCompanyBg" />
    <div class="text">您当前暂无融资记录</div>
    <div class="btn" @click="handleToBorrow">去融一笔</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Image } from 'vant'
Vue.use(Image)
export default {
  props: {},
  data() {
    return {
      noCompanyBg: require('@/assets/images/user/no-company-bg.png'),
    }
  },
  computed: {},
  created() {},
  methods: {
    handleToBorrow() {
      const defaultIndex = this.$store.state.common.defaultIndex
      // 去借一笔
      this.$router.push(defaultIndex)
    },
  },
  components: {},
}
</script>

<style scoped lang="less">
.empty-page {
  text-align: center;
  .bg {
    display: block;
    margin: 1rem auto 0.14rem;
  }
  .text {
    font-size: 0.3rem;
    color: #8b8c91;
    margin-bottom: 0.44rem;
  }
  .btn {
    width: 4rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.5rem;
    margin: 0 auto;
    text-align: center;
    background-color: #4d7bfe;
    color: #fff;
    font-size: 0.34rem;
  }
}
</style>
